import '../../styles/desktop/index.css'
import $ from 'jquery';
// import '../shared/jquery.particleground.js';
import '../shared/particles';
import '../shared/js/app';

// import '../shared/plugins/formValidation/formValidation';
// import '../shared/plugins/formValidation/formValidation.css';

const _dir = $('html').attr('dir');
const isRtl = _dir === 'rtl';
((

    ($) => {
        // $( document ).ready(function() {
        //     $('#particles').particleground({
        //         dotColor: '#5cbdaa',
        //         lineColor: '#5cbdaa'
        //     });
        // });

        const countDownDate = new Date("Jan 1, 2022 00:00:00").getTime();
        const x = setInterval(function () {
            const now = new Date().getTime();

            const distance = countDownDate - now;
            const days = Math.floor(distance / (1000 * 60 * 60 * 24));
            const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((distance % (1000 * 60)) / 1000);

            document.getElementById("days").innerHTML = days;
            document.getElementById("hr").innerHTML = hours;
            document.getElementById("min").innerHTML = minutes;
            document.getElementById("sec").innerHTML = seconds;

            if (distance < 0) {
                clearInterval(x);
                document.getElementById("defaultCountdown").innerHTML = "EXPIRED";
            }
        }, 1000);
    }
)(jQuery));
